import { INIT_SOCKET } from "../actions/actionTypes";

const initialState = {
  liveStreamingSocket: null,
  statsSocket: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_SOCKET:
      return payload;

    default: {
      return state;
    }
  }
};
