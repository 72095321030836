export default [
  {
    name: "Signal",
    path: "/",
    icon: "pi pi-folder",
  },
  {
    name: "Streaming",
    path: "/streaming",
    icon: "pi pi-list",
  },
];
