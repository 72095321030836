import React from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import styles from "./index.module.scss";

export default function Signal() {
  const { signal } = useSelector((state) => state);

  return (
    <div className={styles.signal}>
      <h1 className="pageTitle">Signal</h1>

      <DataTable
        autoLayout
        value={Object.values(signal.data)}
        className="p-datatable-sm"
        dataKey="_id"
        showGridlines
      >
        {signal.fields.map((item, index) => {
          return (
            <Column
              key={index}
              header={item.displayField}
              field={item.dataField}
              // style={{ minWidth: "200px" }}
            />
          );
        })}
      </DataTable>
    </div>
  );
}
