import {
  UPDATE_STREAMING_SERVER_INFO,
  DELETE_STREAMING_SERVER,
} from "./actionTypes";
import { addToast } from "./toasts";
import backendAPI from "../utils/backendAPI/backendAPI";

export const updateStreamingServerInfo = (data) => {
  return { type: UPDATE_STREAMING_SERVER_INFO, payload: data };
};

export const createStreamingServer = (data) => async (dispatch) => {
  try {
    await backendAPI.POST("/api/streaming/servers", data);
    dispatch(
      addToast({
        severity: "success",
        content: "新增成功",
        closable: false,
        life: 3000,
      })
    );
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "發生錯誤",
        closable: false,
        life: 3000,
      })
    );
  }
};

export const deleteStreamingServer = (data) => async (dispatch) => {
  try {
    await backendAPI.DELETE("/api/streaming/servers", { url: data.url });
    dispatch({ type: DELETE_STREAMING_SERVER, payload: data });
    dispatch(
      addToast({
        severity: "success",
        content: "已刪除",
        closable: false,
        life: 3000,
      })
    );
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "發生錯誤",
        closable: false,
        life: 3000,
      })
    );
  }
};
