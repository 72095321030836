import {
  FETCH_LOGIN_COUNTS,
  ADD_ONLINE_USER,
  MINUS_ONLINE_USER,
} from "../actions/actionTypes";

const initialState = {
  online: 0,
  login: 0, // admin only
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LOGIN_COUNTS: {
      return { ...payload };
    }

    case ADD_ONLINE_USER: {
      const total = state.online + 1;
      return { ...state, online: total };
    }

    case MINUS_ONLINE_USER: {
      const total = state.online - 1;

      if (total < 0) return { ...state, online: 0 };
      else return { ...state, online: total };
    }

    default: {
      return state;
    }
  }
};
