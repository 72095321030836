import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import SidebarNavItems from "../SidebarNavItems";
import styles from "./index.module.scss";

const CustomSidebar = ({ match }) => {
  const [visibleLeft, setVisibleLeft] = useState(false);

  return (
    <React.Fragment>
      <Sidebar
        visible={visibleLeft}
        className={styles.maxWidth200}
        showCloseIcon={false}
        onHide={() => setVisibleLeft(false)}
      >
        <SidebarNavItems />
      </Sidebar>
      <div
        className={`p-d-block p-d-md-none ${styles.menuButton}`}
        onClick={() => setVisibleLeft(true)}
      >
        <i className="pi pi-bars" />
      </div>
      <div className={`p-d-none p-d-md-block ${styles.sidebar}`}>
        <SidebarNavItems />
      </div>
    </React.Fragment>
  );
};

export default CustomSidebar;
