import { LOGIN, LOGOUT } from "../actions/actionTypes";
import Cookies from "js-cookie";

const cookie = JSON.parse(Cookies.get("live-events-mesh") || "{}");

const initialState = {
  isAdmin: cookie.isAdmin ? cookie.isAdmin : false,
  token: cookie.token,
  user: cookie.user,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN: {
      return { ...state, ...payload };
    }

    case LOGOUT: {
      return { ...state, isAdmin: false, token: "" };
    }

    case "SET_TOKEN": {
      return { ...state, token: payload };
    }

    default: {
      return state;
    }
  }
};
