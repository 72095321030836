import axios from "axios";
// import history from "../../history";
import store from "../../store";

const baseURL = "https://investor-streaming-backend.meshstream.io";

const backend = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

backend.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      // history.push('/login');
    }
    return Promise.reject(error);
  }
);

export default {
  async GET(url, params, config) {
    try {
      const response = await backend.get(url, { params });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async POST(url, data, config) {
    try {
      const response = await backend.post(url, data, config);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async DELETE(url, data, config) {
    try {
      const response = await backend.delete(url, { data });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async PUT(url, body) {
    try {
      const response = await backend.put(url, body);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
