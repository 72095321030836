import { INIT_SOCKET } from "./actionTypes";
import io from "socket.io-client";
import { addOnlineUser, minusOnlineUser } from "./analytics";
import { updatePublishStatus } from "./admin";
import { updateStreamingStatus } from "./status";
import { fetchLoginCounts } from "./analytics";
import { updateStreamingServerInfo } from "./streamingServer";
import { updateSignalInfo } from "./signal";

import { initChatHistory, addChatMessage } from "./chat";
import { addToast } from "./toasts";

const ENDPOINT = "https://investor-streaming-backend.meshstream.io";

const initSocketInstance = (token) => {
  const socket = io(`${ENDPOINT}/investor-streaming`, {
    query: { jwtToken: token },
    transports: ["websocket"],
  });

  return socket;
};

const initStatsSocketInstance = (token) => {
  const socket = io(`${ENDPOINT}/stats`, {
    query: { jwtToken: token },
    transports: ["websocket"],
  });

  return socket;
};

export const initSocket = () => (dispatch, getState) => {
  const { auth } = getState();

  const liveStreamingSocket = initSocketInstance(auth.token);
  const statsSocket = initStatsSocketInstance(auth.token);

  statsSocket
    .on("streaming-loading", (data) => {
      dispatch(updateStreamingServerInfo(data));
      console.log("streaming-loading", data);
    })
    .on("signal-loading", (data) => {
      dispatch(updateSignalInfo(data));
      console.log("signal-loading", data);
    });

  liveStreamingSocket.on("connect", () => {
    console.log("連接!");
    dispatch(fetchLoginCounts());
    dispatch(
      addToast({
        severity: "success",
        content: "已經成功連接",
        closable: false,
        life: 3000,
      })
    );
  });

  liveStreamingSocket
    .on("isWatching", ({ isWatching }) => {
      console.log("isWatching", isWatching);
    })
    .on("publishStatus", ({ zhPublished, enPublished }) => {
      console.log("publishStatus", zhPublished, enPublished);
      dispatch(
        updatePublishStatus({
          isZhPublishing: zhPublished,
          isEnPublishing: enPublished,
        })
      );
    })
    .on("chatHistory", ({ data }) => {
      dispatch(initChatHistory(data));
    })
    .on("chatMessage", ({ nickname, message }) => {
      dispatch(addChatMessage({ nickname, message }));
    })
    .on("userOnline", () => {
      dispatch(addOnlineUser());
    })
    .on("userOffline", () => {
      dispatch(minusOnlineUser());
    })
    .on("streamingStatus", ({ status }) => {
      dispatch(updateStreamingStatus(status));
    })
    .on("forbidden", ({ event, message }) => {
      console.log("forbidden", { event, message });
    })
    .on("disconnect", () => {
      dispatch(
        addToast({
          severity: "warning",
          content: "連線已中斷",
          closable: false,
          life: 3000,
        })
      );
    });

  dispatch({
    type: INIT_SOCKET,
    payload: { liveStreamingSocket, statsSocket },
  });
};
