import { INIT_CHAT_HISTORY, ADD_CHAT_MESSAGE } from "../actions/actionTypes";

const initialState = [];

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_CHAT_HISTORY:
      return payload;

    case ADD_CHAT_MESSAGE: {
      const newMessages = [...state, payload];

      if (newMessages.length > 50) {
        newMessages.shift();
      }

      return newMessages;
    }

    default: {
      return state;
    }
  }
};
