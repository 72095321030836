import React from "react";
import LoginForm from "../../components/LoginForm";

import sponsor1 from "../../images/sponsor1.png";
import sponsor2 from "../../images/sponsor2.png";
import sponsor3 from "../../images/sponsor3.png";
import sponsor4 from "../../images/sponsor4.png";
import sponsor5 from "../../images/sponsor5.png";
// import sponsor6 from "../../images/sponsor6.png";

import styles from "./index.module.scss";

const Login = () => {
  return (
    <div className={styles.login}>
      <LoginForm />
      <div className={styles.sponsors}>
        <span className={styles.text}>主辦單位</span>
        <img src={sponsor1} alt="sponsor" />
        <img src={sponsor2} alt="sponsor" />
        <img src={sponsor3} alt="sponsor" />
        <img src={sponsor4} alt="sponsor" />
        <img src={sponsor5} alt="sponsor" />
      </div>
    </div>
  );
};

export default Login;
