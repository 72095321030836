import React from "react";
import { useDispatch } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import logo from "./images/form_logo.png";

import styles from "./index.module.scss";

const validationSchema = yup.object({
  nickname: yup.string().required("必填 required"),
  email: yup.string().email("email 格式錯誤").required("必填 required"),
});

const LoginForm = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      nickname: "",
      email: "",
      // phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      const formikData = JSON.parse(JSON.stringify(data));

      dispatch(login(formikData));
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div className={styles.login}>
      <h1 className={styles.logo}>
        <img src={logo} alt="logo" />
        <span>Meshub Dashboard</span>
        {/* <span>世界投資者週聯合論壇</span> */}
      </h1>
      <div className={styles.formContainer}>
        <form
          onSubmit={formik.handleSubmit}
          className="p-fluid"
          autoComplete="off"
          method="post"
          action="/post/"
        >
          <div className="p-field p-mb-6">
            <span className="p-float-label">
              <InputText
                id="nickname"
                name="nickname"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                autoFocus
                autoComplete="off"
                className={classNames({
                  "p-invalid": isFormFieldValid("nickname"),
                })}
              />
              <label
                htmlFor="nickname"
                className={classNames({
                  "p-error": isFormFieldValid("nickname"),
                })}
              >
                暱稱 Nickname
              </label>
            </span>
            {getFormErrorMessage("nickname")}
          </div>
          <div className="p-field p-mb-4">
            <span className="p-float-label">
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                autoComplete="off"
                className={classNames({
                  "p-invalid": isFormFieldValid("email"),
                })}
              />
              <label
                htmlFor="email"
                className={classNames({
                  "p-error": isFormFieldValid("email"),
                })}
              >
                信箱 Email
              </label>
            </span>
            {getFormErrorMessage("email")}
          </div>
          {/* <div className="p-field p-mb-5">
            <span className="p-float-label">
              <InputText
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                type="text"
                autoComplete="off"
                feedback={false}
                className={classNames({
                  "p-invalid": isFormFieldValid("phone"),
                })}
              />
              <label
                htmlFor="phone"
                className={classNames({
                  "p-error": isFormFieldValid("phone"),
                })}
              >
                Phone
              </label>
            </span>
            {getFormErrorMessage("phone")}
          </div> */}
          <Divider />
          <Button type="submit" label="登入 Login" className="p-mt-2" />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
