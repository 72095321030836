import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";

import styles from "./index.module.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  return (
    <div className={styles.topNav}>
      <div className={styles.logo}>
        {/* <img src={MenuLogo} alt="logo" /> */}
      </div>
      <div className={styles.user}>
        <div>
          <span className={styles.name}>Hi，{auth.user.nickname}</span>
          <span className={styles.email}>{auth.user.email}</span>
        </div>
        <span className={styles.logout} onClick={() => dispatch(logout())}>
          登出
        </span>
      </div>
    </div>
  );
};

export default Navbar;
