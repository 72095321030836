import React from "react";
import { useSelector } from "react-redux";
import history from "../../history";
import errorImage from "../../images/404.png";
import { Button } from "primereact/button";

import styles from "./index.module.scss";

const NotFound = () => {
  const { auth } = useSelector((state) => state);

  const handleBack = () => {
    if (auth.isAdmin) {
      history.push("/");
    } else {
      history.push("/viewer");
    }
  };

  return (
    <div className={styles.notFound}>
      <div className="p-grid p-justify-center p-align-center p-mt-5">
        <div className="p-col-10 p-md-5">
          <img src={errorImage} alt="error" />
        </div>
        <div className="p-col-9 p-md-4">
          <h1 className={styles.title}>OOPS! 你要找的頁面不存在</h1>
          <Button label="返回首頁" onClick={handleBack} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
