import React, { useEffect } from 'react';
import history from '../history';

import analytics from './analytics';

export default function useGoogleAnalytics() {
  const location = history.location;

  useEffect(async () => {
    await analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);
}
