import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import FormCreateStreamingServer from "../../components/FormCreateStreamingServer";
import { deleteStreamingServer } from "../../actions/streamingServer";

import styles from "./index.module.scss";

export default function SteamingServer() {
  const dispatch = useDispatch();
  const { streamingServer } = useSelector((state) => state);

  const deleteTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-danger p-button-text"
        onClick={() => dispatch(deleteStreamingServer(rowData))}
      />
    );
  };

  return (
    <div className={styles.streamingServer}>
      <h1 className="pageTitle">Streaming Server</h1>
      <FormCreateStreamingServer />
      <DataTable
        autoLayout
        value={Object.values(streamingServer.data)}
        className="p-datatable-sm"
        dataKey="_id"
        showGridlines
      >
        <Column header="Delete" body={deleteTemplate} />
        {streamingServer.fields.map((item, index) => {
          return (
            <Column
              key={index}
              header={item.displayField}
              field={item.dataField}
            />
          );
        })}
      </DataTable>
    </div>
  );
}
