import {
  UPDATE_STREAMING_SERVER_INFO,
  DELETE_STREAMING_SERVER,
} from "../actions/actionTypes";

const initialState = { fields: [], data: {} };

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_STREAMING_SERVER_INFO:
      return {
        ...state,
        fields: payload.fields,
        data: { ...state.data, [payload.data._id]: payload.data },
      };

    case DELETE_STREAMING_SERVER:
      const newStreamingServerList = { ...state.data };
      delete newStreamingServerList[payload._id];

      return { ...state, data: newStreamingServerList };

    default: {
      return state;
    }
  }
};
