import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeToast } from "../../actions/toasts";
import { Toast } from "primereact/toast";

const Toasts = ({ toast }) => {
  const toastRef = useRef();
  const dispatch = useDispatch();
  // const { toasts } = useSelector();

  useEffect(() => {
    if (toastRef.current) {
      showSuccess(toast);
    }
  }, [toast]);

  const showSuccess = (toast) => {
    toastRef.current.show(toast);
  };

  if (!toast) return null;

  return <Toast ref={toastRef} onRemove={(e) => dispatch(removeToast(e.id))} />;
};

export default Toasts;
