import { combineReducers } from "redux";

import auth from "./auth";
import analytics from "./analytics";
import users from "./users";
import toasts from "./toasts";
import socket from "./socket";
import chat from "./chat";
import status from "./status";
import admin from "./admin";
import streamingServer from "./streamingServer";
import signal from "./signal";

export default combineReducers({
  auth,
  analytics,
  users,
  toasts,
  socket,
  chat,
  status,
  admin,
  streamingServer,
  signal,
});
