import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createStreamingServer } from "../../actions/streamingServer";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const validationSchema = yup.object({
  url: yup.string().required("required"),
  remark: yup.string(),
});

const FormCreateStreamingServer = () => {
  const dispatch = useDispatch();
  const [displayDialog, setDisplayDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      url: "https://streaming-hk-4.meshstream.io",
      remark: "hk4",
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      const formikData = JSON.parse(JSON.stringify(data));
      dispatch(createStreamingServer(formikData));
      setDisplayDialog(false);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onHide = () => {
    setDisplayDialog(false);
    formik.resetForm();
  };

  const onOpen = () => {
    setDisplayDialog(true);
  };

  return (
    <div>
      <Button
        label="Create"
        className="p-button-rounded  p-button-success"
        onClick={onOpen}
      />
      <Dialog
        header={`Create Streaming Server`}
        visible={displayDialog}
        modal
        breakpoints={{ "1440px": "30vw", "760px": "90vw", "576px": "100vw" }}
        onHide={onHide}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="p-fluid p-p-2"
          autoComplete="off"
          method="post"
          action="/post/"
        >
          <div className="p-field p-mb-5 p-mt-3">
            <span className="p-float-label">
              <InputText
                id="url"
                name="url"
                value={formik.values.url}
                onChange={formik.handleChange}
                autoFocus
                autoComplete="off"
                className={classNames({
                  "p-invalid": isFormFieldValid("url"),
                })}
              />
              <label
                htmlFor="url"
                className={classNames({
                  "p-error": isFormFieldValid("url"),
                })}
              >
                Url
              </label>
            </span>
            {getFormErrorMessage("url")}
          </div>
          <div className="p-field p-mb-4">
            <span className="p-float-label">
              <InputText
                id="remark"
                name="remark"
                value={formik.values.remark}
                onChange={formik.handleChange}
                autoComplete="off"
                className={classNames({
                  "p-invalid": isFormFieldValid("remark"),
                })}
              />
              <label
                htmlFor="remark"
                className={classNames({
                  "p-error": isFormFieldValid("remark"),
                })}
              >
                Remark
              </label>
            </span>
            {getFormErrorMessage("remark")}
          </div>
          <Button type="submit" label="Create" className="p-mt-2" />
        </form>
      </Dialog>
    </div>
  );
};

export default FormCreateStreamingServer;
