import ReactGA from "react-ga";
import GA4React from "ga-4-react";

const TRACKING_ID = "UA-206734840-1";
const GA4_ID = "G-YJM5VC9ZRK";
const ga4react = new GA4React(GA4_ID);
let ga4;

async function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(TRACKING_ID, { debug: isDev });
  ga4 = await ga4react.initialize();
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);

  if (ga4) {
    ga4.pageview(path);
  }
}

export default {
  init,
  sendEvent,
  sendPageview,
};
