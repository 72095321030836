import backend from "../utils/backendAPI/backendAPI";
import Cookies from "js-cookie";
import history from "../history";
import { LOGIN, LOGOUT } from "./actionTypes";
import { addToast } from "./toasts";

export const login = (data) => async (dispatch) => {
  try {
    const { isAdmin, token, user } = await backend.POST(
      "/api/users/login",
      data
    );

    dispatch(
      addToast({
        severity: "success",
        content: "登入成功",
        closable: false,
        life: 3000,
      })
    );
    dispatch({ type: LOGIN, payload: { isAdmin, token, user } });

    Cookies.set("live-events-mesh", JSON.stringify({ isAdmin, token, user }), {
      secure: true,
      sameSite: "strict",
    });
  } catch (error) {
    dispatch(
      addToast({
        severity: "error",
        content: "查無此 Email",
        closable: false,
        life: 3000,
      })
    );
    return Promise.reject(error);
  }
};

export const logout = () => async (dispatch, getState) => {
  const { liveStreamingSocket } = getState();
  try {
    Cookies.remove("live-events-mesh");
    dispatch({ type: LOGOUT });
    await backend.POST("/api/users/logout");
    dispatch(
      addToast({
        severity: "success",
        content: "已登出",
        closable: false,
        life: 3000,
      })
    );

    liveStreamingSocket.disconnect();
  } catch (error) {}
};

export const setToken = (token) => {
  return { type: "SET_TOKEN", payload: token };
};
