import backend from "../utils/backendAPI/backendAPI";
import {
  FETCH_LOGIN_COUNTS,
  ADD_ONLINE_USER,
  MINUS_ONLINE_USER,
} from "./actionTypes";

export const fetchLoginCounts = (data) => async (dispatch) => {
  const response = await backend.GET("/api/users/login/counts", data);
  dispatch({ type: FETCH_LOGIN_COUNTS, payload: response.data });
};

export const addOnlineUser = () => {
  return { type: ADD_ONLINE_USER };
};

export const minusOnlineUser = () => {
  return { type: MINUS_ONLINE_USER };
};
