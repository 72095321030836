export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const INIT_SOCKET = "INIT_SOCKET";

export const FETCH_LOGIN_COUNTS = "FETCH_LOGIN_COUNTS";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_SELECTED_USER_HISTORY = "FETCH_SELECTED_USER_HISTORY";

export const ADD_ONLINE_USER = "ADD_ONLINE_USER";
export const MINUS_ONLINE_USER = "MINUS_ONLINE_USER";

export const INIT_CHAT_HISTORY = "INIT_CHAT_HISTORY";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";

export const UPDATE_STREAMING_STATUS = "UPDATE_STREAMING_STATUS";
export const UPDATE_PUBLISH_STATUS = "UPDATE_PUBLISH_STATUS";

export const UPDATE_STREAMING_SERVER_INFO = "UPDATE_STREAMING_SERVER_INFO";
export const CREATE_STREAMING_SERVER = "CREATE_STREAMING_SERVER";
export const UPDATE_SIGNAL_INFO = "UPDATE_SIGNAL_INFO";
export const DELETE_STREAMING_SERVER = "DELETE_STREAMING_SERVER";
