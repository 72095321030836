import { UPDATE_STREAMING_STATUS } from "../actions/actionTypes";

const initialState = {
  streamingStatus: "start",
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_STREAMING_STATUS:
      return { ...state, streamingStatus: payload };

    default: {
      return state;
    }
  }
};
