import { UPDATE_SIGNAL_INFO } from "../actions/actionTypes";

const initialState = { fields: [], data: {} };

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SIGNAL_INFO:
      return {
        ...state,
        fields: payload.fields,
        data: { ...state.data, [payload.data.ip]: payload.data },
      };

    default: {
      return state;
    }
  }
};
