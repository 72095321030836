import { FETCH_USER_LIST } from "../actions/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_LIST: {
      return { ...payload };
    }

    default: {
      return state;
    }
  }
};
