import {
  UPDATE_PUBLISH_STATUS,
  UPDATE_STREAMING_STATUS,
} from "../actions/actionTypes";

const initialState = {
  isZhPublishing: false,
  isEnPublishing: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PUBLISH_STATUS:
      return { ...state, ...payload };

    default: {
      return state;
    }
  }
};
