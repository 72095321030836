import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useGoogleAnalytics from "./hooks/useGaAnalytics";

import history from "./history";
import { initSocket } from "./actions/socket";

import Login from "./views/Login";
import Signal from "./views/Signal";
import StreamingServer from "./views/StreamingServer";
import Toasts from "./components/Toasts";
import CustomSidebar from "./components/CustomSidebar";
import Navbar from "./components/Navbar";
import NotFound from "./views/NotFound";

import styles from "./App.module.scss";

const App = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const { auth, toasts } = useSelector((state) => state);

  const init = async () => {
    dispatch(initSocket());
  };

  useEffect(() => {
    if (auth.token && auth.isAdmin) {
      init();
    }
  }, [auth]);

  return (
    <div className={styles.app}>
      {toasts.map((toast, index) => (
        <Toasts toast={toast} key={index} />
      ))}
      {auth.token ? <Navbar /> : null}
      {auth.isAdmin ? <CustomSidebar /> : null}
      <div className={styles.main}>
        {auth.token && auth.isAdmin ? (
          <Router history={history}>
            <Switch>
              <Route path="/" exact component={Signal} />
              <Route path="/streaming" component={StreamingServer} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
};

export default App;
